import { AppProps } from 'next/app';

import ConfigProvider from '@providers/ConfigProvider';
import SessionProvider from '@providers/SessionProvider';
import UserProvider from '@providers/UserProvider';
import CheckoutProvider from '@providers/CheckoutProvider';
import SnackbarProvider from '@providers/SnackbarProvider';
import SkeletonImageProvider from '@providers/SkeletonImageProvider';
import PageProvider from '@providers/PageProvider';
import FilesProvider from '@providers/FilesProvider';
import CompareProvider from '@providers/CompareProvider';
import HolidaysProvider from '@providers/HolidaysProvider';
import DescriptionData from '@providers/DescriptionDataProvider';

import config from 'config';
import { IntlProvider } from 'utils/intl';

const defaultConfig = {
  shopId: '1',
};

const withProviders =
  // eslint-disable-next-line
    (Component: any) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ pageProps, ...props }: AppProps<Record<string, any>>): JSX.Element => (
      <ConfigProvider config={pageProps.config || defaultConfig}>
        <IntlProvider locale={config.intl.locale} defaultLocale={config.intl.defaultLocale}>
          <SnackbarProvider>
            <SessionProvider connectingIp={pageProps?.connectingIp}>
              <UserProvider>
                <CheckoutProvider>
                  <SkeletonImageProvider productTypes={pageProps?.productTypes}>
                    <PageProvider pages={pageProps?.pages}>
                      <FilesProvider shopFiles={pageProps?.shopFiles} pageFiles={pageProps?.pagesFiles}>
                        <DescriptionData
                          media={pageProps?.descriptionMedia}
                          variants={pageProps?.descriptionVariants}
                          products={pageProps?.descriptionProducts}
                          categories={pageProps?.descriptionCategories}
                          cardVariants={pageProps?.descriptionCardVariants}
                          productVariants={pageProps?.productVariants}
                          categoryVariants={pageProps?.categoryVariants}
                          descriptionArticles={pageProps?.descriptionArticles}
                          descriptionArticlesPhotos={pageProps?.descriptionArticlesPhotos}
                          descriptionArticlesVideos={pageProps?.descriptionArticlesVideos}
                        >
                          <HolidaysProvider holidays={pageProps.holidays}>
                            <CompareProvider>
                              <Component {...props} pageProps={pageProps} />
                            </CompareProvider>
                          </HolidaysProvider>
                        </DescriptionData>
                      </FilesProvider>
                    </PageProvider>
                  </SkeletonImageProvider>
                </CheckoutProvider>
              </UserProvider>
            </SessionProvider>
          </SnackbarProvider>
        </IntlProvider>
      </ConfigProvider>
    );

export default withProviders;

import { useState, ReactNode, useContext } from 'react';
import { useRouter } from 'next/router';

import {
  getUserRoute,
  createAccountRoute,
  changeEmailRoute,
  requestEmailChangeRoute,
  changePasswordRoute,
  resetPasswordRoute,
  resendActivationLinkRoute,
  logoutRoute,
  loginRoute,
} from 'apiRoutes/users';
import { updateAddressRoute } from 'apiRoutes/users/addresses';

import { SessionContext } from '@providers/SessionProvider';
// import { init as initAnalytics } from 'utils/analytics';
import useFetch from 'hooks/useFetch';

import { UserContext, UserContextType } from './UserProvider.context';

type Props = {
  children: ReactNode;
};

const UserProvider = ({ children }: Props): JSX.Element => {
  const { auth } = useContext(SessionContext);
  const [userData, setUserData] = useState<UserContextType['userData']>();
  const [getUser] = useFetch(getUserRoute);
  const [logoutMutation] = useFetch(logoutRoute);
  const [login] = useFetch(loginRoute);
  const [register] = useFetch(createAccountRoute);
  const [updateAddress] = useFetch(updateAddressRoute);
  const [resendActivationLink] = useFetch(resendActivationLinkRoute);
  const [resetPassword] = useFetch(resetPasswordRoute);
  const [changePassword] = useFetch(changePasswordRoute);
  const [requestEmailChangeMutation] = useFetch(requestEmailChangeRoute);
  const [changeEmailMutation] = useFetch(changeEmailRoute);
  const router = useRouter();
  const { Provider } = UserContext;

  const fetchUser: UserContextType['fetchUser'] = async () => {
    if (auth.isLoggedIn()) {
      const result = await getUser();

      if (result.data) {
        setUserData(result?.data?.user);
      } else {
        router.reload();
      }

      return result;
    }

    setUserData(undefined);

    return undefined;
  };

  const logout: UserContextType['logout'] = async () => {
    const result = await logoutMutation();
    setUserData(undefined);

    return result;
  };

  const editUser: UserContextType['editUser'] = async ({ firstName, lastName, phone }) => {
    if (userData) {
      await updateAddress({ first_name: firstName, last_name: lastName, phone, id: userData.id });
      await fetchUser();
    }
  };

  const requestEmailChange: UserContextType['requestEmailChange'] = async ({ newEmail }) => {
    if (userData) {
      return requestEmailChangeMutation({ newEmail });
    }

    return undefined;
  };

  const editEmail: UserContextType['editEmail'] = async ({ token }) => {
    if (userData) {
      const mutation = await changeEmailMutation({ token });
      await fetchUser();
      return mutation;
    }

    return undefined;
  };

  return (
    <Provider
      value={{
        userData,
        register,
        login,
        logout,
        fetchUser,
        editUser,
        editEmail,
        resendActivationLink,
        resetPassword,
        changePassword,
        requestEmailChange,
      }}
    >
      {children}
    </Provider>
  );
};

export default UserProvider;

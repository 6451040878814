import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { SessionVars } from 'types/Session';
import { SessionContext } from '@providers/SessionProvider';
import keysOf from 'utils/keysOf';
import useBoolean from 'hooks/useBoolean';

export type ContextProps = {
  items: Required<SessionVars>['compareIds'];
  count: number;
  ids: number[];
  isRefreshed: boolean;
  isInCompare: (variantId: number, productTypeId: number) => boolean;
  addToCompare: (variantId: number, productTypeId: number) => void;
  removeFromCompare: (variantId: number, productTypeId: number) => void;
  clearComparisonByType: (productTypeId: number) => void;
  clearComparison: () => void;
  reorderItemsInCompare: (variantIdsMap: Record<number, number[]>) => void;
  refresh: () => Promise<void>;
};

const CompareContext = createContext<ContextProps>({
  items: {},
  count: 0,
  ids: [],
  isRefreshed: false,
  isInCompare: () => false,
  addToCompare: () => {},
  removeFromCompare: () => {},
  clearComparisonByType: () => {},
  clearComparison: () => {},
  reorderItemsInCompare: () => {},
  refresh: async () => {},
});

const CompareProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { getSessionVar, setSessionVar, refreshSessionVars, sessionKey } = useContext(SessionContext);

  const [itemsToCompare, setItemsToCompare] = useState<ContextProps['items']>({});
  const [isInitialized, { on: markAsInitialized }] = useBoolean(false);

  const count = keysOf(itemsToCompare).reduce((acc, curr) => acc + itemsToCompare[curr].length, 0);

  const ids = keysOf(itemsToCompare).flatMap((productTypeId) => itemsToCompare[productTypeId]);

  const isInCompare: ContextProps['isInCompare'] = (variantId, productTypeId) =>
    !!itemsToCompare[productTypeId]?.includes(variantId);

  const addToCompare: ContextProps['addToCompare'] = async (variantId, productTypeId) => {
    if (!isInCompare(variantId, productTypeId)) {
      const { data } = await refreshSessionVars();
      const currentCompareIds = data?.sessionVars?.compareIds || {};
      const newItems = {
        ...currentCompareIds,
        [productTypeId]: [...(currentCompareIds[productTypeId] || []), variantId],
      };
      setSessionVar('compareIds', newItems);
      setItemsToCompare(newItems);
    }
  };

  const removeFromCompare: ContextProps['removeFromCompare'] = async (variantId, productTypeId) => {
    if (isInCompare(variantId, productTypeId)) {
      const { data } = await refreshSessionVars();
      const currentCompareIds = data?.sessionVars?.compareIds || {};
      const newItems = {
        ...currentCompareIds,
        [productTypeId]: (currentCompareIds[productTypeId] || []).filter((id) => id !== variantId),
      };
      setSessionVar('compareIds', newItems);
      setItemsToCompare(newItems);
    }
  };

  const reorderItemsInCompare: ContextProps['reorderItemsInCompare'] = async (variantIdsMap) => {
    const newItems = {
      ...itemsToCompare,
      ...variantIdsMap,
    };
    setSessionVar('compareIds', newItems);
    setItemsToCompare(newItems);
  };

  const clearComparisonByType: ContextProps['clearComparisonByType'] = async (productTypeId) => {
    const { data } = await refreshSessionVars();
    const currentCompareIds = data?.sessionVars?.compareIds || {};
    const newItems = {
      ...currentCompareIds,
      [productTypeId]: [],
    };
    setSessionVar('compareIds', newItems);
    setItemsToCompare(newItems);
  };

  const clearComparison: ContextProps['clearComparison'] = () => {
    setSessionVar('compareIds', {});
    setItemsToCompare({});
  };

  const refresh: ContextProps['refresh'] = async () => {
    await refreshSessionVars();
  };

  useEffect(() => {
    const compareIds = getSessionVar('compareIds') || {};

    if (sessionKey) {
      setItemsToCompare(compareIds);
      markAsInitialized();
    }
  }, [sessionKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CompareContext.Provider
      value={{
        items: itemsToCompare,
        count,
        ids,
        isRefreshed: isInitialized,
        isInCompare,
        addToCompare,
        removeFromCompare,
        clearComparisonByType,
        clearComparison,
        reorderItemsInCompare,
        refresh,
      }}
    >
      {children}
    </CompareContext.Provider>
  );
};

export default CompareProvider;
export { CompareContext };

import { APIError } from 'graphql-hooks';

import GraphQLError from 'types/GraphQLError';
import ErrorStatusCode from 'types/ErrorCode';
import { skippableErrorCodes, skippableErrorPaths } from 'consts/graphql';

export type HasuraErrorCode = (typeof skippableErrorCodes)[number];
export type ErrorCode =
  | HasuraErrorCode
  | 'INVALID_GA_MEASUREMENT_ID'
  | 'VALIDATION_ERROR'
  | 'INVALID_METHOD'
  | 'NOT_FOUND'
  | 'NOT_FOUND_IN_ROOT'
  | 'ATTENDEE_ALREADY_JOINED'
  | 'PIN_I'
  | 'INTERNAL';

class ApiError extends Error {
  constructor(
    public code: ErrorCode,
    public message: string,
    public statusCode: ErrorStatusCode
  ) {
    super(message);
    this.name = 'ApiError';
  }

  static fromGraphqlError(error: APIError<GraphQLError> | undefined): ApiError {
    const graphQLError = error?.graphQLErrors?.[0];
    if (skippableErrorPaths.includes(graphQLError?.extensions?.path || '')) {
      return new ApiError('NOT_FOUND_IN_ROOT', graphQLError?.message || 'Not found in root', 404);
    }

    return new ApiError((graphQLError?.extensions?.code as ErrorCode) || 'INTERNAL', graphQLError?.message || '', 500);
  }
}

export type ErrorResponse = Pick<ApiError, 'code' | 'message' | 'statusCode'>;

export default ApiError;

import { useContext, useEffect, useReducer } from 'react';

import { appVersion } from 'consts/app';
import { Session } from 'types/Session';
import { SessionContext } from '@providers/SessionProvider';
import fetchReducer, { initialState, startFetching, loadData, handleError, State } from 'reducers/fetchReducer';
import ApiRoute, { GetResponse } from 'utils/apiRoute';
import tryReloadPage from 'utils/tryReloadPage';
import { clientApiFetch } from 'utils/fetch';
import { ErrorResponse } from 'utils/apiError';

type Props<R extends Record<string, unknown>, P extends Record<string, unknown>> = {
  payload?: P;
  options?: RequestInit;
  manual?: boolean;
  initialData?: R;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FetchResult<C extends ApiRoute<any, any | never>> = Pick<
  State<GetResponse<C>>,
  'data' | 'error' | 'headers'
>;

type UseFetchReturnType<R extends Record<string, unknown>, P extends Record<string, unknown>> = [
  (payload?: P, options?: RequestInit) => Promise<Pick<State<R>, 'data' | 'error' | 'headers'>>,
  State<R>,
];

const useFetch = <R extends Record<string, unknown>, P extends Record<string, unknown>>(
  route: ApiRoute<R, P>,
  { payload, options = {}, manual = true, initialData }: Props<R, P> = {}
): UseFetchReturnType<R, P> => {
  const { checkIfSessionIsRefreshed, refreshSession } = useContext(SessionContext);

  const [state, dispatch] = useReducer(
    fetchReducer<R>,
    initialData
      ? { ...initialState, data: initialData, loaded: true, loading: false, timestamp: new Date().getTime() }
      : { ...initialState, loading: !manual }
  );

  const refetch: UseFetchReturnType<R, P>[0] = async (refetchPayload, refetchOptions) => {
    try {
      dispatch(startFetching());
      await checkIfSessionIsRefreshed();
      const finalPayload = refetchPayload || payload || <P>{};

      const { data, headers, error } = await clientApiFetch(route, finalPayload, {
        ...options,
        ...refetchOptions,
        headers: {
          ...(options?.headers || refetchOptions?.headers || {}),
        },
      });

      const appVersionHeader = headers?.get('x-app-version');

      if (appVersionHeader && appVersionHeader !== appVersion) {
        await tryReloadPage();
      }

      if (data) {
        dispatch(loadData(data, headers));
        if (data.session) {
          refreshSession(data.session as Session);
        }
      } else if (error) {
        dispatch(handleError(error));
      }

      return { data, headers, error };
    } catch (e) {
      const internalError: ErrorResponse = { statusCode: 500, code: 'INTERNAL', message: e.message };
      dispatch(handleError(internalError));
      return { data: undefined, headers: undefined, error: internalError };
    }
  };

  useEffect(() => {
    if (!manual) {
      refetch();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [refetch, state];
};

export default useFetch;

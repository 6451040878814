import { parse } from 'querystring';

import config from 'config';

const retryCountKey = 't';
const timestampKey = 'q';

const tryReloadPage = async () => {
  const queryParams = parse(window.location.search.replace('?', ''));
  const retryCount = Number(queryParams[retryCountKey] || '');

  if (retryCount < config.maxReloadRetries) {
    window.location.href = `/?${new URLSearchParams({
      [retryCountKey]: `${retryCount + 1}`,
      [timestampKey]: `${new Date().getTime()}`,
    })}`;
    await new Promise(() => {});
  }
};

export default tryReloadPage;

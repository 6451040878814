import { ReactNode, useContext, useRef, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import { Session, SessionVars } from 'types/Session';
import auth from 'auth';
import { ConfigContext } from '@providers/ConfigProvider';
import { getSessionVarsRoute, updateSessionVarsRoute } from 'apiRoutes/session';
import { checkCookieRoute } from 'apiRoutes/checkCookie';
import getRefererType from 'utils/getRefererType';
import useBoolean from 'hooks/useBoolean';
import useFetch from 'hooks/useFetch';

import { SessionContext, SessionContextType } from './SessionProvider.context';

type Props = {
  connectingIp: string;
  children: ReactNode;
};

const getPurifiedPath = (path: string, query: ParsedUrlQuery): string => {
  const forbiddenParams = ['gclid', 'ceneo_cid', 'ceneo_std', 'ceneo_spo', 'utm_source', 'utm_medium'];

  return forbiddenParams.reduce(
    (acc, curr) => acc.replace(`&${curr}=${query[curr]}`, '').replace(`${curr}=${query[curr]}&`, ''),
    path
  );
};

const SessionProvider = ({ connectingIp, children }: Props): JSX.Element => {
  const { appUrl } = useContext(ConfigContext);
  const [getSessionVars] = useFetch(getSessionVarsRoute);
  const [updateSessionVars] = useFetch(updateSessionVarsRoute);
  const [checkCookie] = useFetch(checkCookieRoute);
  const [isCookieDisabled, { on: showDisabledCookiePage }] = useBoolean(false);
  const [sessionKey, setSessionKey] = useState(0);
  const [sessionVars, setSessionVars] = useState<SessionVars>();
  const sessionRef = useRef<boolean | undefined>(!!sessionKey);
  const router = useRouter();

  const bumpSessionKey = () => {
    setSessionKey((currentSessionIndex) => currentSessionIndex + 1);
    sessionRef.current = true;
  };

  const checkIfSessionIsRefreshed = async (trailingExecution = false): Promise<boolean> => {
    if (trailingExecution) {
      await new Promise((resolveTimeout) => {
        setTimeout(resolveTimeout, 10);
      });
    }
    return new Promise((resolve) => {
      if (sessionRef.current) {
        resolve(true);
      } else {
        resolve(checkIfSessionIsRefreshed(true));
      }
    });
  };

  const refreshSession = (session: Session) => {
    auth.set(session.auth);
    setSessionVars(session.sessionVars);
    bumpSessionKey();
  };

  const getSessionVar: SessionContextType['getSessionVar'] = (key) => sessionVars?.[key];

  const setSessionVar: SessionContextType['setSessionVar'] = async (key, value) => {
    const result = await updateSessionVars({ sessionVars: { [key]: value } });
    setSessionVars(result.data?.session.sessionVars || {});
    return result;
  };

  const refreshSessionVars: SessionContextType['refreshSessionVars'] = async () => {
    const result = await getSessionVars();
    setSessionVars(result.data?.sessionVars || {});
    return result;
  };

  useEffect(() => {
    const init = async () => {
      const initGclid = router.query.gclid as string | undefined;
      const initCeneoCid = router.query.ceneo_cid as string | undefined;
      const initClid = initGclid || initCeneoCid || document.referrer;
      const refType = getRefererType(router.query, document.referrer);

      const { data } = await updateSessionVars(
        {
          sessionVars: {
            clid: refType !== 'unknown' || !document.referrer.includes(appUrl) ? initClid : undefined,
            referer: refType,
            entry_page: getPurifiedPath(router.asPath, router.query),
            clientData: {
              height: window.screen.height,
              width: window.screen.width,
              userAgent: navigator.userAgent,
              ip: connectingIp,
            },
          },
        },
        {}
      );

      if (data?.session) {
        const { data: cookieData } = navigator.cookieEnabled ? await checkCookie() : { data: { isCookieSet: false } };

        if (!cookieData?.isCookieSet) {
          showDisabledCookiePage();
        } else {
          refreshSession(data.session);
        }
      } else {
        showDisabledCookiePage();
      }
      // initAnalytics(initClid);
    };
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SessionContext.Provider
      value={{
        auth,
        sessionVars,
        sessionKey,
        isCookieDisabled,
        isLoggedIn: auth.isLoggedIn,
        checkIfSessionIsRefreshed,
        setSessionVars,
        refreshSession,
        bumpSessionKey,
        getSessionVar,
        setSessionVar,
        refreshSessionVars,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;

import { defineMessages } from 'utils/intl';

export default defineMessages({
  errorType: 'Błąd cookie',
  message: 'Strona wymaga cookies',
  description:
    'Aby poprawnie korzystać ze strony należy mieć włączoną obsługę plików cookie. Wykorzystujemy wyłącznie cookie sesyjne, co oznacza, że nie korzystamy z plików cookie stron trzecich.',
  footer: 'Potrzebujesz pomocy? Zadzwoń: {phoneNumber}.',
  copyright: 'Copyright © {year} {appShortName}',
});

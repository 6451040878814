import { useContext } from 'react';

import ErrorCookie from 'assets/icons/error-cookie.svg';
import { ConfigContext } from '@providers/ConfigProvider';
import ShopPhone from '@templates/ShopPhone';
import { FormattedMessage } from 'utils/intl';

import classes from './NoCookiePage.module.scss';
import messages from './NoCookiePage.messages';

const NoCookiePage = (): JSX.Element => {
  const { contact } = useContext(ConfigContext);

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainWrapper}>
        <ErrorCookie className={classes.icon} />
        <h1 className={classes.heading}>
          <FormattedMessage {...messages.errorType} />
        </h1>
        <p className={classes.message}>
          <FormattedMessage {...messages.message} />
        </p>
        <p className={classes.description}>
          <FormattedMessage {...messages.description} />
        </p>
      </div>
      <div className={classes.footer}>
        <div className={classes.phone}>
          <FormattedMessage
            {...messages.footer}
            values={{
              phoneNumber: <ShopPhone prefix="" withoutIcon />,
            }}
          />
        </div>
        <FormattedMessage
          {...messages.copyright}
          values={{
            year: new Date().getFullYear(),
            appShortName: contact.appShortName || '',
          }}
          tagName="div"
        />
      </div>
    </div>
  );
};

export default NoCookiePage;

import { ParsedUrlQueryInput, stringify } from 'querystring';

import config from 'config';
import { appVersion } from 'consts/app';
import { skippableErrorCodes } from 'consts/graphql';
import ApiError, { ErrorResponse } from 'utils/apiError';
import { captureException } from 'utils/captureException';
import removeEmptyArrays from 'utils/removeEmptyArrays';
import keysOf from 'utils/keysOf';
import ApiRoute from 'utils/apiRoute';

const defaultOptions: RequestInit = {
  credentials: 'include',
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
    'x-commerce-front-version': appVersion,
  },
};

const methodsWithQueryParams = ['GET', 'DELETE'];

const handleFetch =
  (baseUrl: string) =>
  async <R extends Record<string, unknown>, V extends Record<string, unknown> = Record<string, never>>(
    route: ApiRoute<R, V>,
    payload: V = {} as V,
    options: RequestInit = defaultOptions
  ): Promise<
    { data: R; headers?: Headers; error?: never } | { data?: never; headers?: never; error: ErrorResponse }
  > => {
    const path = route.getPath(payload);
    const finalPayload = route.getPayload(payload);

    const finalOptions = {
      method: route.method,
      ...defaultOptions,
      ...options,
      headers: { ...defaultOptions.headers, ...options.headers },
    };
    const hasEmptyPayload = !keysOf(finalPayload).length;
    const stringifiedParams =
      methodsWithQueryParams.includes(finalOptions.method) && !hasEmptyPayload
        ? stringify(removeEmptyArrays(finalPayload) as ParsedUrlQueryInput)
        : '';

    try {
      const result = await fetch(`${baseUrl}${path}${stringifiedParams ? `?${stringifiedParams}` : ''}`, {
        ...finalOptions,
        body:
          !methodsWithQueryParams.includes(finalOptions.method) && !hasEmptyPayload
            ? JSON.stringify(finalPayload)
            : undefined,
      });

      const data = await result.json();
      const { headers } = result;
      const { code, statusCode, message } = data as ErrorResponse;
      const error = { code, statusCode, message };

      if (error.statusCode && !(skippableErrorCodes as readonly string[]).includes(error.code || '')) {
        const apiError = new ApiError(error.code, error.message, error.statusCode);
        // eslint-disable-next-line no-console
        console.error(apiError);
        captureException(apiError);
      }

      return result.status < 400 ? { data, headers } : { error };
    } catch (e) {
      const error = { code: 'INTERNAL', statusCode: 500, message: e.message } as const;
      // eslint-disable-next-line no-console
      console.error(e);
      captureException(e);
      return { error };
    }
  };

export const serverApiFetch = async <
  R extends Record<string, unknown>,
  V extends Record<string, unknown> = Record<string, never>,
>(
  route: ApiRoute<R, V>,
  payload: V = {} as V,
  options: RequestInit = { headers: {} }
) => handleFetch(`${config.ssrAppUrl}/api`)(route, payload, options);

export const clientApiFetch = handleFetch('/api');

// export const backendFetch = handleFetch(config.backendUrl); // TODO: add when backend is released

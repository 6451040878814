// eslint-disable-next-line no-use-before-define
import React, { useContext, useEffect, useState } from 'react';
import { NextComponentType, NextPageContext } from 'next';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
// import { NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';

import withProviders from 'core/hocs/withProviders';
import { noscriptCss } from 'core/noscript.styles';
import { fontsCss } from 'core/fonts.styles';
import 'styles/global.css';

import config from 'config';
import { appVersion } from 'consts/app';
import { ConfigContext } from '@providers/ConfigProvider';
import { SessionContext } from '@providers/SessionProvider';
import ProgressBar from '@components/atoms/ProgressBar';
import ErrorPage from '@templates/ErrorPage';
import NoCookiePage from '@templates/NoCookiePage';
import CompareFab from '@components/CompareFab';
import AnalyticsKeyPress from '@components/keypress/AnalyticsKeyPress';
// import { trackEvent } from 'utils/analytics';
import tryReloadPage from 'utils/tryReloadPage';
import isBrowser from 'utils/isBrowser';
import useTrack from 'hooks/useTrack';

if (isBrowser && !('IntersectionObserver' in window)) {
  import('intersection-observer');
}

const { routes } = config;

// export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric): void {
//   // @ts-expect-error what with uaTrackingId??
//   trackEvent('web-vitals', name, {
//     event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
//     value: Math.round(name === 'CLS' ? value * 1000 : value),
//     event_label: id,
//     non_interaction: true,
//   });
// }

const globalLoaderExcludedPaths = [routes.search.href];

type ExtendedNextComponentType = NextComponentType<NextPageContext> & { withCompareFab?: boolean };

type AppComponentType = 'error' | 'no-cookie' | 'app';

const getAppComponentType = (hasError: boolean, isCookieDisabled: boolean): AppComponentType => {
  if (hasError) {
    return 'error';
  }

  return isCookieDisabled ? 'no-cookie' : 'app';
};

const MyApp = ({
  Component,
  pageProps, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: AppProps<Record<string, any>> & { Component: ExtendedNextComponentType }): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const { contact } = useContext(ConfigContext);
  const { isCookieDisabled } = useContext(SessionContext);
  const { trackPageView } = useTrack();
  const router = useRouter();

  const appComponentType = getAppComponentType(!!pageProps.error, isCookieDisabled);

  const appComponentMap: Record<AppComponentType, JSX.Element> = {
    error: <ErrorPage errorCode={pageProps.error} />,
    'no-cookie': <NoCookiePage />,
    app: (
      <>
        {Component.withCompareFab && <CompareFab bottomOffset={pageProps.compareFabBottomOffset} />}
        <Component {...pageProps} />
      </>
    ),
  };

  useEffect(() => {
    const handleRouteChangeStart = async (url: string) => {
      if (
        router.asPath !== url &&
        [router.asPath, url].some((pathToCheck) =>
          globalLoaderExcludedPaths.some((path) => !pathToCheck.includes(path))
        )
      ) {
        setIsLoading(true);
      }
    };
    const handleRouteChange = (url: string) => {
      setIsLoading(false);
      trackPageView(url, router.asPath);
    };
    const handleRouteChangeError = () => {
      setIsLoading(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeError', handleRouteChangeError);
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeError', handleRouteChangeError);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, router.events]);

  useEffect(() => {
    const init = async () => {
      if (pageProps.appVersion && pageProps.appVersion !== appVersion) {
        await tryReloadPage();
      }
    };

    init();
  }, [pageProps.appVersion]);

  return (
    <>
      <Head>
        <title>{`${contact.appName}`}</title>
        <link rel="preload" href="/fonts/lato-reg.woff2" as="font" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/lato-bold.woff2" as="font" crossOrigin="anonymous" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* eslint-disable-next-line react/no-danger */}
        <style dangerouslySetInnerHTML={{ __html: fontsCss }} />
        <noscript>
          {/* eslint-disable-next-line react/no-danger */}
          <style dangerouslySetInnerHTML={{ __html: noscriptCss }} />
        </noscript>
      </Head>
      <ProgressBar visible={isLoading} fixed />
      <AnalyticsKeyPress />
      {appComponentMap[appComponentType]}
    </>
  );
};

export default withProviders(MyApp);
